<template>
  <div class="container">
    <form class="form-login" ref="form-login" @submit.prevent="signIn">
      <div class="form-control">
        <input v-model="login" type="email" name="clickup_user_email" placeholder="Email" />
      </div>
      <div class="form-control">
        <input
          v-model="password"
          type="password"
          name="password"
          placeholder="Password"
        />
      </div>
      <div class="form-login__controls">
        <div class="form-login__actions">
          <button type="submit" class="button button-primary">Sign In</button>
          <button
            class="button button-secondary"
            @click.prevent="redirectToRegister"
          >
            Register
          </button>
        </div>
        <div class="form-login__right">
          <a href="#" class="link link--primary" @click.prevent="redirectToForgotPassword">
            Forgot Password
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from "@/store";
import { mapMutations } from 'vuex'
import mtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  name: 'Login',

  data() {
    return {
      login: '',
      password: ''
    }
  },
  methods: {
    ...mapMutations(['setMe', 'setViewLoader']),

    async signIn() {
      try {
        this.setViewLoader(true)
        const response = await mtApi.login({
          email: this.login,
          password: this.password
        });
        this.setMe(response.user)
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('user_id', response.user.id);

        await store.dispatch('fetchActiveUser')
        await store.dispatch('loadAndSetPlatforms')

        this.$router.push({ name: 'time-log-weekly' })
        this.setViewLoader(false)
      } catch (error) {
        this.setViewLoader(false)
        console.error(error)
      }
    },
    redirectToRegister() {
      this.$router.push({ name: 'register' })
    },
    redirectToForgotPassword() {
      this.$router.push({ name: 'forgot-password' })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  padding: 60px;
  background-color: $bg-secondary;

  &__actions {
    display: flex;

    .button {
      margin-right: 1rem;
    }
  }

  &__controls {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.form-control + .form-control {
  margin-top: 1rem;
}
</style>
